import app from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth'

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.db = app.firestore();
    this.analytics = app.analytics();
    this.auth = app.auth();

  }
  logEvent = async (event)=>{
    this.analytics.logEvent(event);
  }

  getTodaysDataRef = async () => {
    const covidDataRef = await this.db.doc("data/todaysData").get() //().collection('data')
    return covidDataRef
  }

  getTodaysData = async () => {
    const covidDataRef = await this.getTodaysDataRef() //().collection('data')
    const covidData = covidDataRef.data() //().collection('data')

    return covidData
  }
  
  getHistoricalDataRef = async () =>{
    const historicalDataRef = await this.db.doc("data/historicalData").get()
    return historicalDataRef
  }

  getTodaysVaccinationData = async () =>{
    console.log("getting today's vaccination ref");
    return await this.db.doc("data/vaccinesToday").get()
  }

  getHistoricalVaccinationData = async () =>{
    console.log("getting historical vaccination ref")
    return await this.db.doc("data/vaccineHistory").get()
  }  

  assignAPIkey = async (email,uid)=>{
    console.log("Assigning API key")
    return this.db.doc(`users/${uid}`).set({email:email,key:uid})
    .catch(error=>{
      console.log('Error assigning API key')
      console.log(error)
      return error
    })

  }
  

  createUserWithEmailAndPassword = async (email,password) =>{
    return this.auth.createUserWithEmailAndPassword(email, password)
    .then(async(data)=>{
      console.log('Created User')
      console.log(data)
      await this.assignAPIkey(email,data.user.uid)
      return data})
    .catch((error) => {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log('errorMessage',errorMessage)
      console.log('errorCode',errorCode)
      return errorCode
    });   
  }
  signInWithEmailAndPassword = async (email,password) =>{
    return this.auth.signInWithEmailAndPassword(email,password)
    .then(data=>{
      console.log('Logged in user')
      return data})
    .catch((error) => {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log('errorMessage',errorMessage)
      console.log('errorCode',errorCode)
      return errorCode
    });
  }

  sendPasswordResetEmail = async (email) =>{
    return this.auth.sendPasswordResetEmail(email).then(function() {
      // Email sent.
      return 'emailResetSent'
    }).catch(function(error) {
      // An error happened.
      return error
    });
  }

  signOut = async () => (app.auth().signOut())

  
  getAuthObject = () => (this.auth)



}

export default Firebase;
